import { css } from "@emotion/react";
import { graphql } from "gatsby";
import { CallToAction, NavLinkRaw, Quotes, Section } from "../components";
import { StyleShape2 } from "../components/Shapes";
import Theme, { ButtonReset } from "../components/Theme";
import { PageLayout } from "../layouts/Page";
import { CallToActionData } from "../queries/DataTypes";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;
    position: relative;
    ::before {
      ${StyleShape2};
      z-index: ${Theme.zIndex.header + 1};
      transform: rotate(145deg);
      top: -200px;
      left: -350px;
    }
  `,
  layout: (count: number) => css`
    display: grid;
    grid-gap: 36px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    grid-auto-flow: row;
    grid-template-columns: minmax(240px, 360px);

    ${Theme.mq.sm_} {
      grid-gap: 48px;
      justify-content: center;
      grid-template-columns: repeat(auto-fit, minmax(240px, 360px));
    }
  `,
  card: (logo: string) => css`
    ${ButtonReset};

    cursor: pointer;
    border: none;
    text-decoration: none;
    margin: 0;
    padding: 0;

    mask: url(/assets/partners/${logo}.svg) 50% 50% no-repeat;
    mask-size: contain;
    background-color: ${Theme.palette.dark};
    width: 100%;
    max-width: 360px;
    padding-top: 33.333%; // 3:1 aspect ratio

    > img {
      display: none;
      object-fit: contain;
      width: 100%;
      height: auto;
    }

    &:hover,
    &:focus {
      padding-top: 0;
      mask: none;
      background-color: transparent;
      > img {
        display: block;
      }
    }
  `,
  quotes: css`
    align-items: center;
    > div {
      ${Theme.mq.md_} {
        width: 60%;
      }

      ${Theme.mq._sm} {
        width: 100%;
      }
    }
  `,
};

interface PageProps {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        keywords: string;
        hero: CallToActionData;
        partners: Array<{
          name: string;
          href: string;
          logo: string;
        }>;
        quoteRefs?: Array<string>;
      };
    };
  };
}

export default ({
  data: {
    mdx: {
      frontmatter: { title, description, keywords, hero, partners, quoteRefs },
    },
  },
}: PageProps) => (
  <PageLayout title={title} description={description} keywords={keywords}>
    <header css={styles.base}>
      <Section marginTop marginBottom>
        <CallToAction width="50%" appearance="secondary" alignment="center" {...hero} />
      </Section>
    </header>
    <main>
      <Section sx={styles.layout(partners.length)}>
        {partners.map(({ name, href, logo }, index) => (
          <NavLinkRaw key={`i${index}`} css={styles.card(logo)} to={href} title={name}>
            <img src={`/assets/partners/${logo}-c.svg`} alt={name} />
          </NavLinkRaw>
        ))}
      </Section>
      {quoteRefs && quoteRefs.length > 0 && (
        <Section marginTop marginBottom sx={styles.quotes}>
          <Quotes keys={quoteRefs} />
        </Section>
      )}
    </main>
  </PageLayout>
);

export const query = graphql`
  query PartnersQuery {
    mdx(fileAbsolutePath: { glob: "**/pages/partners.md" }) {
      frontmatter {
        title
        description
        keywords
        hero {
          heading
          content
        }
        partners {
          name
          href
          logo
        }
        quoteRefs
      }
    }
  }
`;
